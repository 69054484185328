import React, { Component } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

export class MapContainer extends Component {
  render() {
    return (
      <Map google={this.props.google} zoom={20}
        style={{ width: "100%", height: "600px" }}
        initialCenter={{
          lat: 17.385044,
          lng: 78.486671,
        }}
      >
        <Marker onClick={this.onMarkerClick}
          name={'Current location'} />
        <InfoWindow onClose={this.onInfoWindowClose}>
          <div>
            {/* <h1>{irareality}</h1> */}
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: ("AIzaSyBwyUWzedpphw9uXUDp9rHGuJD35ZVMYws")
})(MapContainer)