import React, { useEffect, useState, memo } from 'react';
import { NavLink } from "react-router-dom";
import { FaSearch } from 'react-icons/fa';
import SearchAction from '../../redux/actions/SearchAction';
import { useNavigate } from 'react-router-dom';
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../images/bg_image.webp';

const SearchForm = () => {
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [clearHistory, setClearHistory] = useState(false);
    const navigate = useNavigate();
    const searchDetail = useSelector(state => state.catlogdetail.searchDetail);
    const { getSearchProduct } = SearchAction();
    useEffect(() => {
        if (searchTerm.length > 0) {
            const input_data = { search: searchTerm };
            dispatch(getSearchProduct(input_data));
        }
    }, [searchTerm]);

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate(`/search-result/${searchTerm}`);

        // Manage search history in localStorage
        let searchHistory = JSON.parse(localStorage.getItem('searchHistory')) || [];
        if (!searchHistory.includes(searchTerm)) {
            searchHistory.unshift(searchTerm);
            if (searchHistory.length > 15) {
                searchHistory.pop();
            }
            localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
        }
    };
    // Clear search history when clearHistory state changes
    useEffect(() => {
        if (clearHistory) {
            localStorage.removeItem('searchHistory');
            setClearHistory(false); // Reset state to avoid repeated clearing
        }
    }, [clearHistory]);

    const handleBackClick = () => {
        navigate(-1);
        dispatch({ type: "setsearchDetail", payload: [] });
    };

    const searchHistoryResults = (item) => {
        navigate(`/search-result/${item}`);
    };

    const clearAllHistory = () => {
        setClearHistory(true);
    };

    return (
        <div style={{ display: 'block' }}>
            <div className={searchDetail.length > 0 ? 'searchbar-main-comp' : "search-main-data"}>
                <form onSubmit={handleSubmit}>
                    <div className="input-group search-comp-main-searchbar">
                        <input
                            type="text"
                            className="form-control search-wid"
                            placeholder="Search Here"
                            value={searchTerm}
                            onChange={handleChange}
                        />
                        <button className="input-group-addon primaryColor btn-side-search" type="submit">
                            <FaSearch />
                        </button>
                        <IoCloseSharp className='search-closeicon' onClick={handleBackClick} />
                    </div>
                </form>
                <div>
                    {
                        JSON.parse(localStorage.getItem('searchHistory')) && JSON.parse(localStorage.getItem('searchHistory')).length > 0 &&

                        <div className='search-heading'>
                            <h6 className='search-history-title'>Search History</h6>
                            <div className='searchhistory-delete-button' onClick={clearAllHistory} style={{ cursor: 'pointer' }}>Clear History</div>
                        </div>
                    }
                    <div className='searchHistory-main-elements'>
                        {localStorage.getItem("searchHistory") &&
                            JSON.parse(localStorage.getItem("searchHistory")).map((ele, index) => (
                                <h6 className='searchHistory-elements' key={index} onClick={() => searchHistoryResults(ele)}>{ele}</h6>
                            ))
                        }
                    </div>
                </div>
                {/* <div>
                    {
                        JSON.parse(localStorage.getItem('searchHistory')) && JSON.parse(localStorage.getItem('searchHistory')).length > 0 &&

                        <div className='search-heading'>
                            <h6 className='search-history-title'>Popular Search</h6>
                        </div>
                    }
                    <div className='searchHistory-main-elements'>
                        {localStorage.getItem("searchHistory") &&
                            JSON.parse(localStorage.getItem("searchHistory")).slice(0, 3).map((ele, index) => (
                                <h6 className='searchHistory-elements' key={index} onClick={() => searchHistoryResults(ele)}>{ele}</h6>
                            ))
                        }
                    </div>
                </div> */}
            </div>

            {searchDetail.length > 0 &&
                <div className='searchcomp-search'>
                    {searchDetail.map(ele => (
                        <NavLink key={ele.slug} className="text-decoration-none" to={`/product/${ele.slug}`}>
                            <div className='search-cpomp-searchlist'>
                                <img
                                    className='search-images'
                                    referrerPolicy="no-referrer"
                                    src={ele.product_images[0]}
                                    onError={e => { e.currentTarget.src = logo }}
                                    alt={ele.product_title}
                                />
                                <h6 className='search-result-title'>{ele.product_title}</h6>
                            </div>
                        </NavLink>
                    ))}
                </div>
            }
        </div>
    );
};

export default SearchForm;
