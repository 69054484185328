const initialState = {
  hasMore: true,
  selectedItem:null,
  productcatData: [],
  productcatFiltersData: [],
  searchDetail:[],
  selected_variant:[],
  product_id_variant:0,
  filterChecked:0,
  mobilevariants:false,
  filtersbottomsheet:false,
  checkItems: [],
  showsearchdata:false,
  searchValue:'',
  input_data: undefined,
  filterPage: 1,
  error: null,
  filtertotaldata:"",
  filteritems:"",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'subfiltertotaldata':
      return {
        ...state,
        filtertotaldata: action.payload
      };
    case 'subfilteritems':
      return {
        ...state,
        filteritems: action.payload
      };
    case 'subcategory_data':
      return {
        ...state,
        productcatData: action.payload
      };
    case 'setinput_data':
      return {
        ...state,
        input_data: action.payload
      };
    case 'setFilterPage':
      return {
        ...state,
        filterPage: action.payload
      };
    case 'setproductcatFiltersData':
      return {
        ...state,
        productcatFiltersData: action.payload
      };
    case 'SethasMore':
      return {
        ...state,
        hasMore: action.payload
      };
    case 'setCheckItems':
      return {
        ...state,
        checkItems: action.payload
      };
    case 'setselected_variant':
      return {
        ...state,
        selected_variant: action.payload
      };
    case 'setproduct_id_variant':
      return {
        ...state,
        product_id_variant: action.payload
      };
    case 'setmobilevariants':
      return {
        ...state,
        mobilevariants: action.payload
      };
    case 'setfiltersbottomsheet':
      return {
        ...state,
        filtersbottomsheet: action.payload
      };
    case 'setfilterChecked':
      return {
        ...state,
        filterChecked: action.payload
      };
    case 'setsearchDetail':
      return {
        ...state,
        searchDetail: action.payload
      };
    case 'setValue':
      return {
        ...state,
        searchValue: action.payload
      };
    case 'setshowdata':
      return {
        ...state,
        showsearchdata: action.payload
      };
    case 'setSelectedItem':
      return {
        ...state,
        selectedItem: action.payload
      };
    default:
      return state;
  }
};
