import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CatProducts, MobileSortBy, MobileFilters,NodataFound } from '../components/common';
import { Filters } from '../components/filters';
import { useMediaQuery } from "react-responsive";
import {  useFilters } from '../hooks';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import SearchAction from '../redux/actions/SearchAction';
import { Helmet } from "react-helmet";

const ProductSubCat = () => {
    const dispatch = useDispatch()
    const { slug } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const productcatData = useSelector(state => state.catlogdetail.productcatData);

    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });

    const { getSubcategorydata } = SearchAction();

    const { input_data } = useFilters(handle);

    const location = useLocation()

    var pathname = location.pathname.split("/")[1];

    function handle() {
        dispatch(getSubcategorydata(input_data))
    }

    useEffect(() => {
        dispatch({
            type: 'subcategory_data',
            payload: []
        });
        var queryObj = {};
        for (const entry of searchParams.entries()) {
            queryObj = Object.fromEntries([...searchParams]);
        }

        var finalInput = Object.entries(queryObj).map(([key, value]) => ({
            title: key,
            value: value.split(",").map((ele) => ele.split("_")[0]),
        }))

        if (finalInput.length > 0) {
            var finaloutput = finalInput;
        } else {
            var finaloutput = [];
        }
        var input_data = {
            "limit": 50,
            "filter_update": 1,
            "type": finaloutput.length > 0 ? "filtered" : "non-filtered",
            "finalOutput": finaloutput,
            "filter_attributes": finaloutput,
            "slug": slug,
            "pathname": pathname,
            "page": 1,
        }
        dispatch({ type: 'setinput_data', payload: input_data })

        if (finalInput.length > 0) {
            dispatch(getSubcategorydata(input_data))
        }
    }, [slug])


    return (
        <Container fluid>
                         <Helmet>
                                <title>{localStorage.getItem("title") + " - "  + slug}</title>
                                <link rel="canonical" href={window.location.origin + window.location.pathname} />

                            </Helmet>
            {
                 productcatData.hasOwnProperty("filters") ?
                    <Row className='mt-4'>
                        <Col xxl={2} lg={3} sm={12} xs={12}>
                            {
                                !isDesktoporMobile ?
                                    productcatData.hasOwnProperty("filters") && <Filters filtersdata={productcatData.filters} />
                                    :
                                    <MobileSortBy />
                            }

                        </Col>

                        
                            <CatProducts />
                        

                        {
                            isDesktoporMobile && productcatData.filters.checkbox.length > 0 &&
                            <MobileFilters />
                        }

                    </Row>
                    :
                   
                    
                    
                    productcatData.hasOwnProperty("result") ?   <NodataFound /> : <div className='custom-height'></div>
                    
            }

        </Container>

    );
};

export default ProductSubCat;