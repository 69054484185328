import React from 'react';
import {BsArrowLeftShort} from 'react-icons/bs'
const NodataFound = () => {
    return (
        <div className='nodata'>
            
            <div className='nodata-sub-data-msg'>No Result Found</div>

        </div>
    );
};

export default NodataFound;




