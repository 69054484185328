import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import { MyAccountComp,SubscriptionComp } from '../common';
import {useMediaQuery} from "react-responsive"
const MyaccountSubscription = () => {
    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });
    return (
        <div>
            <Container>
                <Row  className='custom-margin-top'>
                    {
                      !isDesktoporMobile  &&
                      <Col lg={4} sm={12} xs={12}>
                      <MyAccountComp />
                  </Col>
                  
                  
                    }
              
                    
                        <Col lg={6} md={12}>
                        <SubscriptionComp/>
                    </Col>
                    
                   
                </Row>
            </Container>
        </div>
    );
};

export default MyaccountSubscription;