import React, { useContext } from 'react';
import { BsSearch, BsChevronLeft } from 'react-icons/bs';
import Sidebar from '../sidebar/Sidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const MobileHeader1 = () => {
    var location = useLocation();
    const dispatch = useDispatch()
    const addressOpen = useSelector(state => state.addressdata.addressOpen);
    const addressList = useSelector(state => state.addressdata.addressList);
    const userData = useSelector(state => state.masterdata.userData);

    const navigate = useNavigate();

    const goBack = () => {
        dispatch({
            type: 'setAddressopen', payload: 0
        })
        navigate(-1)

    };

    var pathname = location.pathname;

    return (
        <div className="mobile-header address-custom-header" style={{ position: "sticky" }}>
            <div className='header-item-top address-custom-header-top'>
                <div style={{ display: "flex" }}>
                    {pathname == '/' ? <Sidebar /> : <BsChevronLeft

                        onClick={function (event) {
                            if (pathname == '/addresses' && addressOpen == 1) {
                                goBack()
                            } else {
                                if ((addressList.length <= 0 && userData.hasOwnProperty("cust_type") && userData.cust_type == "1")) {
                                    goBack()
                                } else {
                                    dispatch({
                                        type: 'setAddressopen', payload: addressOpen == 5 ? 0 : (addressOpen == 3 || addressOpen == 4) ? 1 : addressOpen == 11 ? 0 : addressOpen - 1
                                    })
                                }
                            }
                        }}


                        size="23" />}
                    <div className="custom-header-top-title">
                        {(addressOpen == 1 && pathname == '/addresses') ? "My Addresses" : (addressOpen == 1) && "Select Delivery Address"}
                        {addressOpen == 2 && "Add Delivery Address"}
                        {addressOpen == 3 && "Edit Delivery Address"}
                        {addressOpen == 4 && "Payment Method"}
                        {addressOpen == 11 && "Select Store"}
                        {addressOpen == 5 && "Add / Edit Billing Address"}
                    </div>
                </div>


            </div>

        </div>
    );
};

export default MobileHeader1;