import React, { useState, useEffect, useContext } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { CustomDatePicker } from '../common';
import { BiSolidDownArrow } from 'react-icons/bi';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

const CheckotDeliverySlot = ({ type }) => {
    const dispatch = useDispatch()
    const [state, setactivestate] = useState(1);
    const [state1, setactivestate1] = useState(11);
    const [delivery_slots, setdelivery_slots] = useState([]);

    const masterData = useSelector(state => state.masterdata.masterData);
    const deliverslot = useSelector(state => state.orderdetail.deliverslot);
    const deliverdatetime = useSelector(state => state.orderdetail.deliverdatetime);

    const handleClick = (id) => {
        if (id == 1) {
            dispatch({
                type: 'setdeliverdatetime', payload: {
                    "date": moment().format('DD-MM-YYYY'),
                    "day": moment().format('dddd'),
                }
            })

            var ty = delivery_slots.filter(ele => ele.day == moment().format('dddd'));

        }
        if (id == 2) {
            dispatch({
                type: 'setdeliverdatetime', payload: {
                    "date": moment().add(1, 'days').format('DD-MM-YYYY'),
                    "day": moment().add(1, 'days').format('dddd'),
                }
            })

            var ty = delivery_slots.filter(ele => ele.day == moment().add(1, 'days').format('dddd'));
        } else {
            var ty = []
        }

        setactivestate(id)

        if (ty.length > 0) {
            setactivestate1(ty[0].time[0].time);
        }
    }

    const handleClick1 = (id) => {
        setactivestate1(id)
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div
            className='time_dropdown'
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <span><BiSolidDownArrow className='dropdown_icon' /></span>
        </div>
    ));


    const handleClickSLOT = (time) => {
        dispatch({
            type: 'setdeliverdatetime', payload: {
                "date": moment().format('DD-MM-YYYY'),
                "day": moment().format('dddd'),
                "time": time
            }
        })
        setactivestate1(time)
        dispatch({ type: 'setdeliverslot', payload: false })
    }

    useEffect(() => {
        if (Object.keys(masterData).length > 0 && masterData.vendor.hasOwnProperty("delivery")) {
            setdelivery_slots(masterData.vendor.delivery.slot)
            dispatch({
                type: 'setdeliverdatetime', payload: {
                    "date": moment().format('DD-MM-YYYY'),
                    "day": moment().format('dddd'),
                }
            })

            if (masterData.vendor.delivery.slot.length > 0) {
                setactivestate1(masterData.vendor.delivery.slot[0].time[0].time)
            }
        }
    }, [masterData])


    return (
        <div className={`${type == 'mobile' ? "mobile-delivery-margin20" : "delivery_slot_parent"}`}>
            <div className='slot_custom_heading'>Select Date</div>
            <div className='delivery_slot_list mt-2'>
                <div className={`delivery_slot ${state == 1 ? "activedelivery_slot" : ""} `} onClick={() => handleClick(1)}>Today {moment().format('DD MMM')}</div>
                <div className={`delivery_slot ${state == 2 ? "activedelivery_slot" : ""} `} onClick={() => handleClick(2)}>Tomorrow, {moment().add(1, 'days').format('DD MMM')}</div>
                <div className={`delivery_slot ${state == 3 ? "activedelivery_slot" : ""} `} onClick={() => handleClick(3)}>
                    <CustomDatePicker state={state} masterData={masterData} setactivestate1={setactivestate1} />
                </div>
            </div>


            {
                delivery_slots.length > 0 &&
                <>
                    <div className='mobile-checkout-divider1'></div>
                    <div className='slot_custom_heading'>Select Time</div>
                    {
                        type != 'mobile' ?

                            <div className='mt-2'>
                                <Row className='delivery_slot_time_list mt-2'>
                                    {
                                        delivery_slots.filter(ele => ele.day == deliverdatetime.day).length > 0 && delivery_slots.filter(ele => ele.day == deliverdatetime.day).map(ele2 => {
                                            return ele2.time.map(ele3 => {
                                                return (
                                                    <Col lg={3} className={`delivery_time_slot ${state1 == ele3.time ? "activedelivery_slot" : ""} `} onClick={() => handleClick1(ele3.time)}>{ele3.time}</Col>
                                                )
                                            })


                                        })
                                    }


                                </Row>

                                {/* <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" as={CustomToggle}>
                                Select Time Slot
                            </Dropdown.Toggle>

                            <Dropdown.Menu className='time-dropdown-menu'>
                                <Dropdown.Item href="#/action-1">10:00 hrs - 12:00 hrs</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">12:00 hrs - 14:00 hrs</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">14:00 hrs - 16:00 hrs</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">16:00 hrs - 18:00 hrs</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">18:00 hrs - 20:00 hrs</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">20:00 hrs - 22:00 hrs</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
                            </div>
                            :
                            <div className='mobile-timelist'>
                                {
                                    delivery_slots.filter(ele => ele.day == deliverdatetime.day).length > 0 && delivery_slots.filter(ele => ele.day == deliverdatetime.day).map(ele2 => {
                                        return ele2.time.map(ele3 => {
                                            return (
                                                <div className={`mobile-timeitem ${state1 == ele3.time ? "mobile-timeitem-active" : ""} `} onClick={() => handleClickSLOT(ele3.time)}>
                                                    {ele3.time}
                                                </div>
                                            )
                                        })
                                    })
                                }
                            </div>
                    }
                </>
            }
        </div>

    );
};

export default CheckotDeliverySlot;