import React from 'react';

const ProductJsonData = () => {
    var data = [
        {
            "id": 1,
            "url": "/my-account",
            "name": "My Account"
        },
        {
            "id": 2,
            "url": "/my-orders",
            "name": "My Orders"
        },
        {
            "id": 3,
            "url": "/order-details",
            "name": "Order Details"
        },
        {
            "id": 4,
            "url": "/cart",
            "name": "My Cart"
        },
        {
            "id": 5,
            "url": "/checkout",
            "name": "Order Review"
        },
    ]

    return { data };
};

export default ProductJsonData;

