import React, { useContext, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Container, Row, Col, Modal } from 'react-bootstrap';
import { ProfileForm, OtpForm, MyAccountComp } from '../components/common';

import MasterAction from '../redux/actions/MasterAction';
import { useSelector,useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
const Myaccount = () => {
    const isDesktopOrMobile = useMediaQuery({ query: '(max-width: 991px)' })

    const dispatch = useDispatch()

    const openModal = useSelector(state => state.authdetail.openModal);

    const handleClose = () => {
        dispatch({
            type: 'setopenModal', payload: !openModal
        })
        dispatch({
            type: 'setotpform', payload: 0
        })
    }

    const { getProfileData } = MasterAction();

    useEffect(() => {
        dispatch(getProfileData())
    }, [])



    return (
        <Container>
             <Helmet>
        <title>{localStorage.getItem("title")} - Myaccount</title>
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
        {/* <GoogleAnalytics trackingId={"G-ZDTRF1HJ43"}/> */}
      </Helmet>
            <Row className='custom-margin-top'>
                <Col lg={4} sm={12} xs={12}>
                    <MyAccountComp />
                </Col>
                {
                    !isDesktopOrMobile &&
                    <>
                        <Col lg={5}>
                            <ProfileForm />
                        </Col>
                        <Col lg={2}>

                        </Col>
                    </>
                }


            </Row>

            <Modal
                show={openModal}
                size="sm"
                className='my-custom-modal'
                centered
                onHide={() => handleClose()}
            >

                <Modal.Body>
                    <OtpForm />
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default Myaccount;