import React from 'react';

const BillingAddress = ({heading,name,address,state,phone}) => {
    return (
        <div className='billingDetails'>
            <div className='billing'>
                <div className='billingHead'>{heading}</div>
                <div className='billingAdd'>{name} ,{address},{state}, {phone}</div>
            </div>
        </div>
    );
};

export default BillingAddress;