import React, { useState } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { BsFillPersonFill, BsFillBoxFill, BsGrid3X3GapFill, BsFillHeartFill } from 'react-icons/bs';
import { FaHouse } from 'react-icons/fa6';
import { FiShoppingCart } from "react-icons/fi";
import ProductJsonData from '../../components/data/ProductJsonData';
import { useLocation } from 'react-router-dom';
const BottomTabs = () => {
    const [active, setactive] = useState(0);
    const { data } = ProductJsonData();

    var location = useLocation();

    var pathname = location.pathname;

    const tabs = [{
        route: "/",
        icon: <FaHouse color={pathname == '/' ? "#ff7e00" : "#666666"} />,
        label: "Home"
    },
    {
        route: "/my-account",
        icon: <BsFillPersonFill color={pathname == '/my-account' ? "#ff7e00" : "#666666"} />,
        label: "Account"
    },
    {
        route: "/cart",
        icon: <FiShoppingCart color={pathname == '/cart' ? "#ff7e00" : "#666666"} />,
        label: "Cart"
    }
    ]
    return (
        <div>
            <nav className="navbar bottom-tabs fixed-bottom botm-tabs  navbar-light d-block d-lg-none bottom-tab-nav" role="BottomTabs" >
                <Nav className="w-100">
                    <div className=" d-flex flex-row justify-content-around w-100">
                        {
                            tabs.map((tab, index) => (
                                <NavItem key={`tab-${index}`} >
                                    <NavLink to={tab.route} className="nav-link bottom-nav-link">
                                        <div className="row text-center">
                                            {tab.icon}
                                            <div className="bottom-tab-label" style={{ color: `${pathname == tab.route ? "#ff7e00" : "#666666"}` }}>{tab.label}</div>
                                        </div>
                                    </NavLink>
                                </NavItem>
                            ))
                        }
                    </div>
                </Nav>
            </nav>
        </div>
    )
};

export default BottomTabs;