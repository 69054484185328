import React, { useState, memo } from 'react'
import { SearchForm } from '../components/common';
import { Container } from 'react-bootstrap';
const SearchComp = () => {
    return (
        <div className='searchbcomp'>

            <Container>
                <SearchForm />
            </Container>
        </div>
    )
}
export default memo(SearchComp);