import React from 'react';
import { BsChevronRight } from "react-icons/bs"
import { useNavigate, NavLink } from 'react-router-dom';
const BreadComp = ({ title0, url0, titlecat0, urlcat0, titlesubcat1, urlsubcat1, titlesubsubcat2, urlsubsubcat2, title1, url1, url2, url3, title4, title2, title3 }) => {
    const navigate = useNavigate()
    return (
        <div className='breadcomp'>
            <div className='breadcompHome' onClick={() => navigate('/')} >
                <div className='breadcomp-product-titles'>Home</div></div>
            {
                localStorage.getItem("vendor_id") == 1 ?

                    <>
                        {
                            title0 != '' &&
                            <>
                                <div className='breadcomp-arrow'><BsChevronRight /></div>
                                <NavLink className="text-decoration-none" to={url0}>
                                    <div className='breadcomp-product-titles'>{title0}</div>
                                </NavLink></>
                        }

                    </> :
                    <>
                        {
                            titlecat0 != '' &&
                            <>
                                <div className='breadcomp-arrow'><BsChevronRight /></div>
                                <NavLink className="text-decoration-none" to={urlcat0}>
                                    <div className='breadcomp-product-titles'>{titlecat0}</div>
                                </NavLink></>
                        }

                    </>

            }
            {

                localStorage.getItem("vendor_id") == 1 ?

                    <>
                        {
                            title1 != '' &&
                            <>
                                <div className='breadcomp-arrow'><BsChevronRight /></div>

                                <NavLink className="text-decoration-none" to={url1}>
                                    <div className='breadcomp-product-titles'>{title1}</div>
                                </NavLink></>
                        }

                    </> : <>
                        {
                            titlesubcat1 != '' &&
                            <>

                                <div className='breadcomp-arrow'><BsChevronRight /></div>

                                <NavLink className="text-decoration-none" to={urlsubcat1}>
                                    <div className='breadcomp-product-titles'>{titlesubcat1}</div>
                                </NavLink></>
                        }

                    </>
            }



            {
                localStorage.getItem("vendor_id") == 1 ?

                    <>
                        {
                            title2 != '' &&
                            <>

                                <div className='breadcomp-arrow'><BsChevronRight /></div>
                                <NavLink className="text-decoration-none" to={url2}>
                                    <div className='breadcomp-product-titles'>{title2}</div>
                                </NavLink> </>
                        }

                    </> : <>
                        {
                            titlesubsubcat2 != '' &&
                            <>

                                <div className='breadcomp-arrow'><BsChevronRight /></div>
                                <NavLink className="text-decoration-none" to={urlsubsubcat2}>
                                    <div className='breadcomp-product-titles'>{titlesubsubcat2}</div>
                                </NavLink>
                            </>
                        }

                    </>

            }
            {/* {
                title3 != '' &&
                <>
                    <div className='breadcomp-arrow'><BsChevronRight /></div>
                    <NavLink className="text-decoration-none" to={url3}>
                        <div className='breadcomp-product-titles'>{title3}</div>
                    </NavLink>
                </>

            } */}
            {
                // title4 != '' &&
                <>
                    {/* <div className='breadcomp-arrow'><BsChevronRight /></div> */}
                    {
                        // url3 != undefined ? <NavLink className="text-decoration-none" to={url3}>
                        // <div className='breadcomp-product-title'>{title4}</div>
                        // </NavLink>
                        // :
                        // <div className='breadcomp-product-title'>{title4}</div>
                    }

                </>

            }





        </div>
    );
};

export default BreadComp;