import React from 'react';
import Apis from "../../services/Apis";
// import { useNavigate } from 'react-router-dom';
import { notify } from '../../components/common';
const MasterAction = () => {
  // const navigate=  useNavigate();

  const { HomepageComponent, categorylistdata, Multifullcarouseldata, Carouselapidata, categoryListService, getOtpService, getNavigationlist, updateAuthPasswordservice, getMasterDataService, varifyOtpService, getStaticPageListdata, getUserProfile, gvt, updatepwdService, globaljson } = Apis()



  const Multifullcarouseldatalist = (input_data, api) => async dispatch => {
    const response = await Multifullcarouseldata(input_data, api);
    if (response.data.status == 'success') {

      dispatch({
        type: 'setselectedmultiplecarousel',
        payload: response.data.result
      });
    } else dispatch({
      type: 'setselectedmultiplecarousel',
      payload: []
    });
  }

  const Carouselapidatalist = (input_data, api) => async dispatch => {

    const response = await Carouselapidata(input_data, api);

    if (response.data.status == 'success') {
      dispatch({
        type: 'setfullcarouselapidata',
        payload: response.data.result
      });
    } else dispatch({
      type: 'setFooterdata',
      payload: []
    });
  }
  const getStaticPageListdatadata = () => async dispatch => {

    const response = await getStaticPageListdata();
    // return response;
    if (response.data.status == 'success') {
      dispatch({
        type: 'setFooterdata',
        payload: response.data.pagelist
      });
    } else dispatch({
      type: 'setFooterdata',
      payload: []
    });
  }

  const updatepwd = (input_data) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await updatepwdService(input_data);
    if (response.status == 200) {
      if (response.data.status == "success") {
        dispatch({
          type: 'setloader',
          payload: false
        });
        dispatch({
          type: 'setStep',
          payload: 3
        });
      } else {
        dispatch({
          type: 'setloader',
          payload: false
        });

      }
    }

  };
  const updateAuthPasswordservicedata = (input_data) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await updateAuthPasswordservice(input_data);
    if (response.status == 200) {
      if (response.data.status == "success") {
        dispatch({
          type: 'setloader',
          payload: false
        });
        notify(response.data.message)

        dispatch({
          type: 'setStep',
          payload: 3
        });
        dispatch({
          type: 'setforgotsuccess',
          payload: response.data.status
        });
        // navigate("/cart");
      } else {
        dispatch({
          type: 'setloader',
          payload: false
        });

      }
    }

  };

  const varifyOtppassword = (input_data, errors) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await varifyOtpService(input_data);
    if (response.status == 200) {


      if (input_data.hasOwnProperty("email")) {
        if (response.data.status == "success") {
          dispatch({
            type: 'setloader',
            payload: false
          });
          dispatch({
            type: 'setStep',
            payload: 3
          });

        } else {
          errors.forgot_otp = response.data.message
          dispatch({
            type: 'setloader',
            payload: false
          });
        }
      } else {
        alert()
        var op = response.data.message.split('":\"')[2].split('"\}')[0];
        if (op == 'OTP Mismatch') {
          errors.forgot_otp = op;
        }
        if (op == 'OTP Matched') {

          dispatch({
            type: 'setStep',
            payload: 3
          });
          dispatch({
            type: 'setotpToken',
            payload: ""
          });

        }
        dispatch({
          type: 'setloader',
          payload: false
        });
      }
    }

  };

  const getOtppassword = (input_data, errors) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await getOtpService(input_data);
    if (response.status == 200) {
      if (response.data.status == "success") {
        dispatch({
          type: 'setloader',
          payload: false
        });

        dispatch({
          type: 'setotpToken',
          payload: response.data.token
        });
        dispatch({
          type: 'setforgotid',
          payload: response.data.data
        });
        dispatch({
          type: 'setforgot_login_type',
          payload: response.data.login_type
        });
        dispatch({
          type: 'setStep',
          payload: 2
        });

      } else {
        errors.email = response.data.message
        dispatch({
          type: 'setloader',
          payload: false
        });
      }
    }

  };
  const categorylistdatalimit = () => async dispatch => {
    dispatch({
      type: 'setLoaderstatus1',
      payload: true
    });

    // var input_data = {
    //   "page_key": "home"
    // }
    const response = await categorylistdata();
    if (response.status == 200) {
      if (localStorage.getItem("categorylimit") === null || localStorage.getItem("categorylimit") != response.data.sectionList[0].sectionResult[0].limit) {
        localStorage.setItem("categorylimit", response.data.sectionList[0].sectionResult[0].limit)
      }
      if (localStorage.getItem("carousel_product_view") === null || localStorage.getItem("carousel_product_view") != response.data.sectionList[0].sectionResult[0].carousel_product_view) {
        localStorage.setItem("carousel_product_view", response.data.sectionList[0].sectionResult[0].carousel_product_view)
      }

      dispatch({
        type: 'setCategorylimit',
        payload: localStorage.getItem("categorylimit")
      });
      dispatch({
        type: 'setcategorylimitdata',
        payload: response.data.sectionList[0]
      });
      // dispatch({
      //   type: 'setcarouselproductview',
      //   payload: localStorage.getItem("carousel_product_view")
      // });
      dispatch({
        type: 'setLoaderstatus1',
        payload: false
      });

    }
  };
  const globaljsondata = () => async dispatch => {
    // dispatch({
    //   type: 'setLoaderstatus1',
    //   payload: true
    // });

    // var input_data = {
    //   "page_key": "home"
    // }
    const response = await globaljson();
    if (response.status === 200) {
      // Checking if the sectionList contains an element with component "global-setting"
      if (response.data.sectionList.map((ele) => ele.component === "global-setting")) {
        dispatch({
          type: 'setNavigationStatus',
          payload: response.data.sectionList // You can set this to the specific data you want to dispatch
        });
      }
    }

  };



  const getHomepageData = () => async dispatch => {
    dispatch({
      type: 'setLoaderstatus1',
      payload: true
    });
    var input_data = {
      "page_key": "home"
    }
    const response = await HomepageComponent(input_data);
    if (response.status == 200) {
      dispatch({
        type: 'homepage_detail',
        payload: [response.data]
      });
      dispatch({
        type: 'setLoaderstatus1',
        payload: false
      });
      // if (response.data.status == 'success') {
      //   dispatch({
      //     type: 'setLoaderstatus1',
      //     payload: false
      //   });
      //   dispatch({
      //     type: 'homepage_detail',
      //     payload: [response.data]
      //   });
      // } else {
      //   dispatch({
      //     type: 'homepage_detail',
      //     payload: []
      //   });
      //   dispatch({
      //     type: 'setLoaderstatus1',
      //     payload: false
      //   });
      // }
    }
  };


  const getMasterData = () => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await getMasterDataService();
    if (response.status == 200) {
      if (response.data.status == 'success') {

        if (localStorage.getItem("cdn") === null || localStorage.getItem("cdn") != response.data.data.vendor.cdn_domain) {
          localStorage.setItem('cdn', response.data.data.vendor.cdn_domain);
        }
        if (localStorage.getItem("cdn_domain") === null || localStorage.getItem("cdn_domain") != response.data.data.vendor.cdn_domain) {
          localStorage.setItem('cdn_domain', response.data.data.vendor.cdn_domain);
        }
        if (localStorage.getItem("vendor_contact") === null || localStorage.getItem("vendor_contact") != response.data.data.vendor.vendor_contact) {
          localStorage.setItem('vendor_contact', response.data.data.vendor.vendor_contact);
        }
        if (localStorage.getItem("agency_name") === null || localStorage.getItem("agency_name") != response.data.data.vendor.agency_name) {
          localStorage.setItem('agency_name', response.data.data.vendor.agency_name);
        }
        if (localStorage.getItem("favicon") === null || localStorage.getItem("favicon") != response.data.data.vendor.favicon) {
          localStorage.setItem('favicon', response.data.data.vendor.favicon);
        }
        if (localStorage.getItem("title") === null) {
          localStorage.setItem('title', response.data.data.vendor.agency_name);
        }
        if (localStorage.getItem("company_logo") === null || localStorage.getItem("company_logo") != response.data.data.vendor.company_logo) {
          localStorage.setItem('company_logo', response.data.data.vendor.company_logo);
        }
        if (localStorage.getItem("ga_id") === null || localStorage.getItem("ga_id") != response.data.data.vendor.ga_id) {
          localStorage.setItem('ga_id', response.data.data.vendor.ga_id);
        }
        if (localStorage.getItem("currency") === null || localStorage.getItem("currency") != response.data.data.vendor.currency) {
          localStorage.setItem('currency', response.data.data.vendor.currency);
        }
        if (localStorage.getItem("verification_method") === null || localStorage.getItem("verification_method") != response.data.data.vendor.verification_method) {
          localStorage.setItem('verification_method', response.data.data.vendor.verification_method);
        }
        if (localStorage.getItem("domain_phone") === null || localStorage.getItem("domain_phone") != response.data.data.vendor.domain_phone) {
          localStorage.setItem('domain_phone', response.data.data.vendor.domain_phone);
        }
        if (localStorage.getItem("domain_whatsapp") === null || localStorage.getItem("domain_whatsapp") != response.data.data.vendor.domain_whatsapp) {
          localStorage.setItem('domain_whatsapp', response.data.data.vendor.domain_whatsapp);
        }
        if (localStorage.getItem("domain_email") === null || localStorage.getItem("domain_email") != response.data.data.vendor.domain_email) {
          localStorage.setItem('domain_email', response.data.data.vendor.domain_email);
        }
        if (localStorage.getItem("domain_title") === null || localStorage.getItem("domain_title") != response.data.data.vendor.domain_title) {
          localStorage.setItem('domain_title', response.data.data.vendor.domain_title);
        }
        if (localStorage.getItem("domain_descrption") === null || localStorage.getItem("domain_descrption") != response.data.data.vendor.domain_descrption) {
          localStorage.setItem('domain_descrption', response.data.data.vendor.domain_descrption);
        }
        if (localStorage.getItem("domain_address") === null || localStorage.getItem("domain_address") != response.data.data.vendor.domain_address) {
          localStorage.setItem('domain_address', response.data.data.vendor.domain_address);
        }
        if (localStorage.getItem("address") === null || localStorage.getItem("address") != response.data.data.vendor.address) {
          localStorage.setItem('address', response.data.data.vendor.address);
        }
        if (localStorage.getItem("vendor_id") === null || localStorage.getItem("vendor_id") != response.data.data.vendor.vendor_id) {
          localStorage.setItem('vendor_id', response.data.data.vendor.vendor_id);
        }
        if (localStorage.getItem("android_app_url") === null || localStorage.getItem("android_app_url") != response.data.data.vendor.android_app_url) {
          localStorage.setItem("android_app_url", response.data.data.vendor.android_app_url);
        }
        if (localStorage.getItem("ios_app_url") === null || localStorage.getItem("ios_app_url") != response.data.data.vendor.ios_app_url) {
          localStorage.setItem("ios_app_url", response.data.data.vendor.ios_app_url);
        }
        if (localStorage.getItem("footer_logo") === null || localStorage.getItem("footer_logo") != response.data.data.vendor.footer_logo) {
          localStorage.setItem('footer_logo', response.data.data.vendor.footer_logo);
        }
        if (localStorage.getItem("website_copyright") === null || localStorage.getItem("website_copyright") != response.data.data.vendor.website_copyright) {
          localStorage.setItem('website_copyright', response.data.data.vendor.website_copyright);
        }
        if (localStorage.getItem("cancel_statuses") === null || localStorage.getItem("cancel_statuses") != response.data.data.vendor.cancel_statuses) {
          localStorage.setItem('cancel_statuses', response.data.data.vendor.cancel_statuses);
        }
        if (localStorage.getItem("review_flag") === null || localStorage.getItem("review_flag") != response.data.data.vendor.review_flag) {
          localStorage.setItem('review_flag', response.data.data.vendor.review_flag);
        }
        if (localStorage.getItem("header_bg") === null || localStorage.getItem("header_bg") != response.data.data.vendor.theme.css.colors.header_bg) {
          localStorage.setItem('header_bg', response.data.data.vendor.theme.css.colors.header_bg);
        }
        if (localStorage.getItem("primary_color") === null || localStorage.getItem("primary_color") != response.data.data.vendor.theme.css.colors.primary_color) {
          localStorage.setItem('primary_color', response.data.data.vendor.theme.css.colors.primary_color);
        }
        if (localStorage.getItem("header_text_color") === null || localStorage.getItem("header_text_color") != response.data.data.vendor.theme.css.colors.header_text_color) {
          localStorage.setItem('header_text_color', response.data.data.vendor.theme.css.colors.header_text_color);
        }
        if (localStorage.getItem("nav_bg") === null || localStorage.getItem("nav_bg") != response.data.data.vendor.theme.css.colors.nav_bg) {
          localStorage.setItem('nav-_bg', response.data.data.vendor.theme.css.colors.nav_bg);
        }
        if (localStorage.getItem("base_url") === null || localStorage.getItem("base_url") != response.data.data.vendor.base_url) {
          localStorage.setItem('base_url', response.data.data.vendor.base_url);
        }
        if (localStorage.getItem("nav_text_color") === null || localStorage.getItem("nav_text_color") != response.data.data.vendor.theme.css.colors.nav_text_color) {
          localStorage.setItem('nav_text_color', response.data.data.vendor.theme.css.colors.nav_text_color);
        }
        if (localStorage.getItem("app_aspect_ratio") === null || localStorage.getItem("app_aspect_ratio") != response.data.data.vendor.theme.css.colors.app_aspect_ratio) {
          localStorage.setItem('app_aspect_ratio', response.data.data.vendor.theme.css.colors.app_aspect_ratio);
        }

        dispatch({
          type: 'masterData',
          payload: response.data.data
        });
        dispatch({
          type: 'setloader',
          payload: false
        });
      } else {
        dispatch({
          type: 'masterData',
          payload: []
        });
        dispatch({
          type: 'setloader',
          payload: false
        });
      }
    }
  };
  const getNavigationlistdata = () => async dispatch => {
    const response = await getNavigationlist()

    if (response.data.status == 'success') {
      // alert()
      dispatch({
        type: 'setnavdata',
        payload: response.data.result,

      });
    } else {
      dispatch({
        type: 'setnavdata',
        payload: []
      });
    }
  }

  const getcategorylistData = () => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await categoryListService();

    if (response.status == 200) {
      if (response.data.status == 'success') {
        dispatch({
          type: 'categorylistData',
          payload: response.data.categoryList
        });
        dispatch({
          type: 'setloader',
          payload: false
        });
      } else {
        dispatch({
          type: 'categorylistData',
          payload: []
        });
        dispatch({
          type: 'setloader',
          payload: false
        });
      }
    }
  };

  const getProfileData = () => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await getUserProfile();
    if (response.data.status == "success") {
      dispatch({
        type: 'setprofileData',
        payload: [response.data.data]
      });
      dispatch({
        type: 'setloader',
        payload: false
      });
    }
    if (response.status == 200) {
      dispatch({
        type: 'setloader',
        payload: false
      });
    }
  };

  const getVendorToken = () => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });

    const response = await gvt();

    if (response.status == 200) {
      dispatch({
        type: 'setvendor_token',
        payload: response.data.vt
      });
      dispatch({
        type: 'setloader',
        payload: false
      });
      localStorage.setItem('qwerty', response.data.vt);
    }

  };

  return {
    getHomepageData: getHomepageData,
    getMasterData: getMasterData,
    getcategorylistData: getcategorylistData,
    getProfileData: getProfileData,
    getNavigationlistdata: getNavigationlistdata,
    getVendorToken: getVendorToken,
    getOtppassword: getOtppassword,
    updateAuthPasswordservicedata: updateAuthPasswordservicedata,
    varifyOtppassword: varifyOtppassword,
    updatepwd: updatepwd,
    categorylistdatalimit: categorylistdatalimit,
    globaljsondata: globaljsondata,
    getStaticPageListdatadata: getStaticPageListdatadata,
    Carouselapidatalist: Carouselapidatalist,
    Multifullcarouseldatalist: Multifullcarouseldatalist,
  }
};

export default MasterAction;