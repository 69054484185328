import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import Modal from "react-bootstrap/Modal";
import { Col } from 'react-bootstrap';
import { Pagination } from 'swiper/modules';
import logo from '../../images/bg_image.webp';
const ProductDetailSwiperMobile = ({ productDetail }) => {

    const [showModal, setshowMOdal] = useState(false);

    const [index, setindex] = useState(0);

    const slidechange = (e) => {
        setindex(e.realIndex)
    }


    const SwiperComponent = ({ images, type }) => {
        return (
            <Swiper
                style={{ position: "relative" }}
                loop={true}
                initialSlide={index}
                onSlideChange={(e) => slidechange(e)}
                pagination={true}
                modules={[Pagination]}
                className="mySwiperMobile"
            >
                {
                    images.map((img, index1) => {
                        return (
                            <SwiperSlide

                                onClick={() => type == "nomodal" && setshowMOdal(!showModal)}
                                key={index1}> <img referrerPolicy="no-referrer"  src={img}
                                onError={e => {
                                    e.currentTarget.src = logo
                                  }}
                                 />

                            </SwiperSlide>
                        )
                    })
                }

            </Swiper>
        )
    }


    return (
        <>
            <Col md={12} sm={12} xs={12}>

                <SwiperComponent images={productDetail.product_images} type={"nomodal"} />

                <Modal
                    show={showModal}
                    centered
                    className="modellearn"
                    onHide={() => setshowMOdal(!showModal)}
                    size="lg"
                    fullscreen={true}
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Header closeButton className="d-flex">
                        <div>{`${index + 1}/${productDetail.product_images.length}`}</div>
                    </Modal.Header>
                    <Modal.Body className='product-detail-modal'>
                        <SwiperComponent images={productDetail.product_images} />
                    </Modal.Body>
                </Modal>

            </Col>
        </>
    );
};

export default ProductDetailSwiperMobile;