import React from "react";
import { FaStar, FaRegStar, FaPlus } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import Varientdropdown from "./Varientdropdown";
import { useMediaQuery } from "react-responsive";
import MobilVarient from "./MobileVarient";
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../images/bg_image.webp';
import { useSelector } from "react-redux";
import CartAction from '../../redux/actions/CartAction';
const Productcard1 = ({ discount, image, className1, slug, title, productid, price, status, rating, discription, mrp, short_description, stock_status, variantList }) => {
    const addtocartpage = useSelector(state => state.cartdetail.addtocartpage);
    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });
    const productvarientdata = useSelector(state => state.masterdata.productvarientdata);
    const authStatus = useSelector(state => state.masterdata.authStatus);
    const [searchParams, setSearchParams] = useSearchParams();
    const { addToCartProduct } = CartAction();
    const { addToWishList } = CartAction(searchParams);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const renderStars = () => {
        let stars = [];
        for (let i = 0; i < 5; i++) {
            if (i < rating) {
                stars.push(<FaStar key={i} />);
            } else {
                stars.push(<FaRegStar key={i} />);
            }
        }
        return stars;
    };
    const handleAddToCart = (id) => {
        if (!authStatus) {
            const randomId = Math.floor(Math.random() * 100000000);
            if (localStorage.getItem("sessionId") === null) {
                localStorage.setItem('sessionId', randomId);
            }
        }
        const input_data = {
            ...(variantList && variantList.length > 0 ? { "variantid": productvarientdata.variantid } : {}),
            "product_id": id,
            "quantity": 1,
            "type": 'product',
            ...(authStatus == false && { "session_id": localStorage.getItem("sessionId") })
        }
        dispatch(addToCartProduct(input_data))
    }
    const clickwishlist = (id, type) => {

        dispatch(addToWishList(id, type))
    }
    if (addtocartpage === "Added to cart") {
        // alert()
        navigate("/cart");
        dispatch({
            type: 'setAddtocartpage',
            payload: ""
        });
    }
    // else if (addtocartpage) {
    //     // navigate(1);
    // }

    return (

        <div className="single-makal-product productcard1-main">
            <NavLink to={`/product/${slug}`} className={"text-decoration-none"}>
                <div className="pro-img">
                    <div className="productcard1-images image_aspect_ratio">
                        <img src={image} onError={e => {
                            e.currentTarget.src = logo
                        }} />
                    </div>
                    {/* <div className="productcard-status-discount">
                        {stock_status == 0 && <div className="sticker-new secondaryColor">{"Out Of Stock"}</div>}
                        {
                            stock_status == 1 && discount > 0 &&
                            <div className="sticker-sale primaryColor">{discount}% OFF</div>
                        }
                    </div> */}


                    {/* <div className="quick-view-pro">
                    <div data-bs-toggle="modal" data-bs-target="#product-window" className="quick-view"></div>
                </div> */}
                </div>
            </NavLink>
            <div className={`productcard1-details`}>
                {/* <h6 className={`pro-title ${className1}`}>{title}</h6> */}

                {/* <p> */}
                <div className={`pro-content `}>
                    <h6 className={`pro-title list-title ${className1}`}>{title}</h6>
                    {
                        variantList && variantList.length > 0 && (
                            isDesktoporMobile ?

                                <MobilVarient variantList={variantList} />

                                :
                                <div style={{ width: "50%" }}>
                                    <Varientdropdown variantList={variantList} />
                                </div>
                        )
                    }
                    <div className="">
                        {
                            rating && rating.length > 0 &&
                            <div className="rating primaryColorcolor ">
                                {renderStars()}
                            </div>
                        }

                    </div>

                    {/* {
                        variantList && variantList.length > 0 ?
                            <div>
                                <p className="price-category-list">
                                    <span className={`price ${className1}`}>{localStorage.getItem("currency") + productvarientdata.selling_price}</span>
                                    {
                                        stock_status == 0 ? "" :
                                            <>
                                                {mrp !== price && <span className="price cross-price">{localStorage.getItem("currency") + productvarientdata.mrp}</span>}
                                            </>
                                    }

                                </p>
                            </div> :
                            <div>
                                <p className="price-category-list">
                                    <span className={`price ${className1}`}>{localStorage.getItem("currency") + price}</span>
                                    {
                                        stock_status == 0 ? "" :
                                            <>
                                                {mrp !== price && <span className="price cross-price">{localStorage.getItem("currency") + mrp}</span>}
                                            </>
                                    }

                                </p>
                            </div>
                    } */}
                    {/* <div >
                        <p className="price-category-list">
                            <span className={`price  ${className1}`}>{localStorage.getItem("currency") + price}</span>
                            {
                                mrp != price &&
                                <span className="price cross-price">{localStorage.getItem("currency") + mrp}</span>
                            }
                        </p>

                    </div> */}


                </div>
                {/* </p> */}

                {/* <div className="productcard1-discription">
                    {
                        short_description
                    }

                </div> */}
                  <div className="actions-secondary  ">
                            {
                                rating && rating.length > 0 &&
                                <div className="rating primaryColorcolor ">
                                    {renderStars()}
                                </div>
                            }
                        </div>
                {
                    variantList && variantList.length > 0 ?
                        <div>
                            <p className="price-category-list">
                                <span className={`price ${className1}`}><span className="productcard-price-symbol">{localStorage.getItem("currency")}</span> {productvarientdata.selling_price}</span>
                                {
                                    stock_status == 0 ? "" :
                                        <>
                                            {mrp !== price && <span className="price cross-price">{localStorage.getItem("currency") + productvarientdata.mrp}</span>}
                                        </>
                                }
                                {
                                    discount > 0 &&
                                    (
                                        variantList && variantList.length > 0 ?
                                            (productvarientdata.rstatus == 1 &&
                                                <div className="sticker-sale primaryColor">  {Math.round(100 - (productvarientdata.selling_price / productvarientdata.mrp) * 100)}% OFF</div>
                                            )
                                            :
                                            <div className="sticker-sale primaryColor">{discount}% OFF</div>
                                    )
                                }
                            </p>
                        </div> :
                        <div>
                            <p className="price-category-list">
                                <span className={`pricecost ${className1}`}><span className="productcard-price-symbol">{localStorage.getItem("currency")}</span>{price}</span>
                                {
                                    stock_status == 0 ? "" :
                                        <>
                                            {mrp !== price && <span className="pricecostcross cross-price">{localStorage.getItem("currency") + mrp}</span>}
                                        </>
                                }
                                {
                                    discount > 0 &&
                                    (
                                        variantList && variantList.length > 0 ?
                                            (productvarientdata.rstatus == 1 &&
                                                <div className=" ">  {Math.round(100 - (productvarientdata.selling_price / productvarientdata.mrp) * 100)}% </div>
                                            )
                                            :
                                            <div className="productdiscount  primaryColorcolor">{discount}% </div>
                                    )
                                }
                            </p>
                        </div>
                }

                <div className="">
                    <div className="">
                        {


                            stock_status == 0 ?
                                <div className="">
                                    {
                                        authStatus ?

                                            <div className={`add-to-cartbtn primaryColor secondaryColorHover  ${className1}`} data-toggle="tooltip" data-original-title="Move to Wishlist" onClick={() => clickwishlist(productid, 'product')}>
                                              Add to Wishlist
                                            </div> :
                                            <NavLink to={"/login"} className="text-decoration-none">
                                                <div className={`add-to-cartbtn primaryColor secondaryColorHover  ${className1}`} data-toggle="tooltip" data-original-title="Move to Wishlist">
                                                    Add to Wishlist
                                                </div>
                                            </NavLink>
                                    }

                                </div> :
                                < div className="">
                                    <div className={`add-to-cartbtn primaryColor secondaryColorHover ${className1}`} data-toggle="tooltip" data-original-title="Add to Cart" onClick={() => handleAddToCart(productid)}>
                                        Add To Cart
                                    </div>
                                </div>
                        }
                    </div>


                </div>
            </div>
        </div>

    );
};

export default Productcard1;
