import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { GiCycle } from "react-icons/gi";
import OrderAction from "../redux/actions/OrderAction";
import { NodataFound } from '../components/common'
import CartAction from "../redux/actions/CartAction";
import moment from "moment";
import { useNavigate, useSearchParams } from 'react-router-dom';
// const [searchParams, setSearchParams] = useSearchParams();
// import CartAction from "../../redux/actions/CartAction";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import logo from '../images/bg_image.webp';
import { useSelector, useDispatch } from "react-redux";
const MyOrder = () => {
    const { getCustomerOrderdata } = OrderAction();
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const myorders = useSelector(state => state.orderdetail.myorders);
    // const { addToCart } = CartAction();
    const cancel = localStorage.getItem("cancel_statuses");
    const { addToCart, addToWishList, } = CartAction(searchParams);
    const { cancelOrderlist, returnOrderServicelist } = CartAction();

    // const arr= [];
    // arr.push(cancel)

    // const arr=[];
    //  arr=localStorage.getItem("cancel_statuses")

    const rateddata = (id, productdata, productid) => {
        const data = {
            id: id,
            productdata: productdata,
            productid: productid,
        }
        dispatch({
            type: 'setRatingdata',
            payload: data
        });

    }


    const cancelclick = (productid, variantid, quantity, orderid, map_id) => {
        const input_data = {
            productid: productid,
            variantid: variantid,
            quantity: quantity,
            order_id: orderid,
            orderDetail_id: map_id,
        }
        dispatch(cancelOrderlist(input_data));
        // dispatch(returnOrderServicelist(input_data));
    }
    const cancelclicks = (productid, variantid, quantity, orderid, map_id) => {
        const input_data = {
            productid: productid,
            variantid: variantid,
            quantity: quantity,
            order_id: orderid,
            orderDetail_id: map_id,
        }
        // dispatch(cancelOrderlist(input_data));
        dispatch(returnOrderServicelist(input_data));
    }

    useEffect(() => {
        dispatch(getCustomerOrderdata());
    }, [])
    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 576px)` });
    return (
        <div className="myorder-bottom">
            <Container >
                <Helmet>
                    <title>{localStorage.getItem("title")} - MyOrder</title>
                    <link rel="canonical" href={window.location.origin + window.location.pathname} />
                    {/* <GoogleAnalytics trackingId={"G-ZDTRF1HJ43"}/> */}
                </Helmet>
                {
                    myorders != undefined ?
                        myorders.length > 0 ?
                            myorders.map(ele => {
                                const deliveredDate = ele.updated_date
                                return (
                                    <Row className="myorder-main-div">
                                        <div className="myorder-sub-div" >
                                            <Row className="order-main-title-div">
                                                <Col lg={6} md={12} className="order-coloum AAAA">
                                                    <Col lg={4} md={4} sm={4} xs={4} className="order-coloum">
                                                        <div className=" order-data-title">
                                                            <div className="order-detail-subtitle">ORDER PLACED</div>
                                                            <div className="order-status-details">{moment(ele.order_date).format('DD-MM-YYYY')}</div>
                                                        </div>

                                                    </Col>
                                                    <Col lg={4} md={4} sm={4} xs={4} className="order-coloum">
                                                        <div className="order-main-title-sub-div order-data-title">
                                                            <div className="order-detail-subtitle">TOTAL</div>
                                                            <div className="order-status-details"> {localStorage.getItem("currency") + ele.total_amount}</div>
                                                        </div>

                                                    </Col>
                                                    <Col lg={4} md={4} sm={4} xs={4} className="order-coloum">
                                                        <div className="order-main-title-sub-div  order-data-title">
                                                            <div className="order-detail-subtitle">SHIP TO</div>
                                                            <div className="order-status-details">{ele?.customer_name}</div>

                                                        </div>

                                                    </Col>
                                                </Col>

                                                <Col lg={6} md={12} sm={12} className="order-id-div order-coloum">
                                                    <div className="order-titles">
                                                        <div className="order-detail-subtitle">ORDER  {ele.orderno}</div>
                                                        <div className="order-title-sub">
                                                            {
                                                                <NavLink to={`/order-success/${ele.id}`}>
                                                                    <div className="view-order-title text-decoration-none">view order details</div>
                                                                </NavLink>
                                                            }

                                                            {
                                                                ele.invoice_link &&
                                                                <NavLink to={ele.invoice_link}>
                                                                    <div className="invoice-title">Invoice</div>
                                                                </NavLink>
                                                            }

                                                        </div>
                                                    </div>

                                                </Col>
                                            </Row>


                                            {
                                                ele.order_details.map(ele1 => {
                                                    return (
                                                        <Row className="order-data-fulldetail">
                                                            <Col lg={9} className="order-coloum">
                                                                <div className="orderpage-detils">
                                                                    <div className="order-delivered-date">
                                                                        <div>{ele1.current_orderstatus}</div>
                                                                        {
                                                                            // (ele1.current_orderstatus == "Delivered") &&
                                                                            (ele1.orderstatus == 5) &&
                                                                            <div className="delevered-date">  {moment(ele.deliveredDate).format('DD-MM-YYYY')}</div>

                                                                        }

                                                                        {/* <div>{setDate((ele.deliveredDate).getDate() + 7)}</div> */}


                                                                        {/* if(ele1.current_orderstatus == Delivered){
                                                                       
                                                                    } */}




                                                                    </div>
                                                                    {/* <div>Package was handed directly to a security guard.</div> */}
                                                                    <div className="order-full-details">
                                                                        <div>
                                                                            <img
                                                                                referrerPolicy="no-referrer"
                                                                                className="order-image"
                                                                                src={
                                                                                    localStorage.getItem("cdn") +
                                                                                    (ele1?.product_detail?.[0]?.product_images?.[0] || '')
                                                                                }
                                                                                onError={e => {
                                                                                    e.currentTarget.src = logo;
                                                                                }}
                                                                            />

                                                                        </div>
                                                                        <div className="order-image-details">
                                                                            <div className="order-image-title">
                                                                                {ele1?.product_detail[0]?.product_title}
                                                                            </div>
                                                                            {
                                                                                // (ele1.current_orderstatus == "Delivered") &&


                                                                                (ele1.orderstatus == 5) &&
                                                                                <div className="order-return-details">
                                                                                    <div> Return window closed on  {moment(ele.deliveredDate).add(localStorage.getItem("return_period"), 'days').format('DD-MM-YYYY')}</div>


                                                                                </div>
                                                                            }

                                                                            {/* <div className="product-detail-buttongroup product-detal-cart_btn">
                                  <div className="product-detail-button cart-btn orderbutton">buy it again</div>
                                  <div  className='product-detail-button btn-2 orderbutton' >view your item</div>
                                </div> */}
                                                                            {
                                                                                !isDesktoporMobile &&
                                                                                <div className="order-buttons-main">
                                                                                    {

                                                                                        <div className="order-button-buy "
                                                                                            onClick={() =>
                                                                                                dispatch(addToCart(ele1.productid, 'product'))
                                                                                            }

                                                                                        >

                                                                                            <GiCycle className="buy-icon" />

                                                                                            buy it again</div>

                                                                                    }

                                                                                    {

                                                                                        <NavLink to={`/product/${ele1?.product_detail[0]?.slug}`} className={"text-decoration-none"}>
                                                                                            <div className='order-button-view' >view your item</div>
                                                                                        </NavLink>
                                                                                    }

                                                                                </div>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <>
                                                                {
                                                                    isDesktoporMobile &&
                                                                    <div className="order-buttons-main">
                                                                        {
                                                                            // <NavLink className="text-decoration-none  button-width" to="/cart">
                                                                            <div className="order-button-buy"
                                                                                onClick={() =>
                                                                                    dispatch(addToCart(ele1.productid, 'product'))
                                                                                }

                                                                            >

                                                                                {/* <img className="buy-image" src="https://m.media-amazon.com/images/S/sash/7uhR68oBHEcdiIr.png"></img> */}
                                                                                <GiCycle className="buy-icon" />

                                                                                buy it again</div>
                                                                            // </NavLink>
                                                                        }

                                                                        {
                                                                            <NavLink to={`/product/${ele1.product_detail[0].slug}`} className={"text-decoration-none"}>
                                                                                <div className='order-button-view' >view your item</div>
                                                                            </NavLink>
                                                                        }

                                                                    </div>

                                                                }
                                                            </>

                                                            <Col lg={3} className="order-coloum order-right-div">
                                                                <div className="aaaaa">

                                                                    {
                                                                        (cancel.includes(ele.orderstatus)) && (

                                                                            <div className='order-button-right' onClick={() => cancelclick(ele1.productid, ele1.variantid, ele1.quantity, ele1.orderid, ele1.map_id)} >Cancel Order</div>

                                                                        )
                                                                    }
                                                                    {

                                                                        localStorage.getItem("return_period") != " " && ele1.orderstatus == 5 &&
                                                                        <div className='order-button-right' onClick={() => cancelclicks(ele1.productid, ele1.variantid, ele1.quantity, ele1.orderid, ele1.map_id)} >Return</div>
                                                                    }

                                                                    {ele1.orderstatus == 5 &&
                                                                        <NavLink className={"text-decoration-none"} to={`/RatedProduct/${ele1.map_id}`}>
                                                                            {/* <div className='order-button-right '  onClick={() => dispatch({ type: 'setRatingdata', payload: (ele1.map_id,ele1.product_detail) })} >write a product review</div> */}
                                                                            <div className='order-button-right ' onClick={() => rateddata(ele1.map_id, ele1.product_detail, ele1.productid)} >write a product review</div>
                                                                        </NavLink>
                                                                    }

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            }

                                            {/* <Row className="bottom">
                                        <Col lg={12} className="order-coloum">
                                            <div className="">Archive order</div>
                                        </Col>
                                    </Row> */}
                                        </div>
                                    </Row>
                                )
                            }) : <NodataFound />
                        : <div className='custom-height'></div>
                }

            </Container>
        </div>
    )
}
export default MyOrder;