import React, { useContext } from "react";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";

const useCookie = () => {

    const dispatch = useDispatch()

    const cookies = new Cookies();

    const setCookie = async (token) => {
        dispatch({ type: 'setauthToken', payload: token })
        cookies.set("jwt_token", token, { path: '/' });
        const userData = jwtDecode(cookies.get("jwt_token"));
        dispatch({ type: 'setuserData', payload: userData })
    };

    const getCookie = () => {
        if (cookies.get("jwt_token") != undefined) {
            dispatch({ type: 'setauthToken', payload: cookies.get("jwt_token", { path: '/' }) })
            const userData = jwtDecode(cookies.get("jwt_token"));
            dispatch({ type: 'setuserData', payload: userData })
            dispatch({ type: 'setauthStatus', payload: true })
            // useName = userData.first_name;
        }
    };

    const deleteCookie = () => {
        if (cookies.get("jwt_token") != undefined) {
            cookies.remove("jwt_token", { path: '/' })
            dispatch({ type: 'setauthToken', payload: '' })
            dispatch({ type: 'setuserData', payload: {} })
            dispatch({ type: 'setauthStatus', payload: false })
            localStorage.removeItem("sessionId");
            window.location.href = "/"
        }
    };

    return { setCookie, getCookie, deleteCookie };
};

export default useCookie;
