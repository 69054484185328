import React, { useContext, useEffect, useState } from 'react';
import { BsXLg } from 'react-icons/bs';
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from 'react-redux';

const PaymentMethodComp = ({ screentype, paymentData, type }) => {
    const dispatch = useDispatch()
    const cartDetail = useSelector(state => state.cartdetail.cartDetail);
    const bottomsheet = useSelector(state => state.cartdetail.bottomsheet);
    const discount = useSelector(state => state.cartdetail.discount);

    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });


    const [updateddiscount, setupdateddiscount] = useState(0);


    useEffect(() => {
        setupdateddiscount(cartDetail.total_mrp - cartDetail.total)
    }, [cartDetail])


    return (
        <>

            {
                type == 'dropdown' ?
                    <div className='justify-space-between d-flex'>
                        <div className='payment_header'>Payment Details</div>
                        <BsXLg onClick={() => dispatch({ type: 'setbottomsheet', payload: !bottomsheet })} />
                    </div> :
                    <div className='payment_header'>Payment Details</div>
            }

            <div className='detail_row'>
                <div className='detail_row_left'>MRP Total</div>
                <div className='detail_row_right'>{screentype == 'order-detail' ? paymentData.selling_price : localStorage.getItem("currency") + cartDetail.total_mrp}</div>
            </div>
            {
                updateddiscount != 0 &&
                <div className='detail_row'>
                    <div className='detail_row_left'>Discount</div>
                    <div className='detail_row_right active-font-size-green'>- {screentype == 'order-detail' ? 0 : localStorage.getItem("currency") + updateddiscount}</div>
                </div>
            }

            <div className='detail_row'>
                <div className='detail_row_left'>Delivery Charges</div>
                <div className='detail_row_right active-font-size-green'>{cartDetail.total_shipping_cost}</div>
            </div>
            <div className='detail_row'>
                <div className='detail_row_left'>Total</div>
                <div className='detail_row_right  active-font-size-black'>{screentype == 'order-detail' ? localStorage.getItem("currency") + paymentData.selling_price : localStorage.getItem("currency") + ((cartDetail.total + cartDetail.total_shipping_cost) - discount)}</div>
            </div>

        </>
    );
};

export default PaymentMethodComp;