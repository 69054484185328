import React, { useState, useContext, useEffect } from 'react';
import {
    Form,
} from "react-bootstrap";

const PickupAddressList = ({ type, showbtn, addressList }) => {

    const [checked, setchecked] = useState();

    return (
        <>
            {/* <div className='accordion-address-body-listitem' >
                <div className="d-flex justify-content-between" >
                    <div >
                        <Form.Check
                            type="radio"
                            name="addressType"
                            value={10}
                            label={"sai murali mart agencies"}
                            onChange={(e) => setchecked(e.target.value)}
                            checked={true}
                            // defaultChecked={each.default_address ? true : false}
                            aria-label="radio 1"
                        />
                    </div>

                </div>
                <div className='checkout-list-address'>
                    KPHB,Dharma reddy colony,jntu
                    <div>Hyderabad, Telangana - <span>500032</span></div>
                    <div>8500332883</div>


                </div>
            </div>
            <div className='accordion-address-body-listitem' >
                <div className="d-flex justify-content-between" >
                    <div >
                        <Form.Check
                            type="radio"
                            name="addressType"
                            value={11}
                            label={"Rohit Sharma"}
                            onChange={(e) => setchecked(e.target.value)}
                            checked={true}
                            // defaultChecked={each.default_address ? true : false}
                            aria-label="radio 1"
                        />
                    </div>

                </div>
                <div className='checkout-list-address'>
                    vadodara,near aiims hospital
                    <div>mumbai, maharastara - <span>712505</span></div>
                    <div>9883371010</div>


                </div>
            </div>
            <div className='accordion-address-body-listitem' >
                <div className="d-flex justify-content-between" >
                    <div >
                        <Form.Check
                            type="radio"
                            name="addressType"
                            value={12}
                            label={"virat kohli"}
                            onChange={(e) => setchecked(e.target.value)}
                            checked={true}
                            // defaultChecked={each.default_address ? true : false}
                            aria-label="radio 1"
                        />
                    </div>

                </div>
                <div className='checkout-list-address'>
                    g.t road near hanuman temple
                    <div>kongi, New delhi - <span>741258</span></div>
                    <div>7412581964</div>


                </div>
            </div>
            <div className='accordion-address-body-listitem' >
                <div className="d-flex justify-content-between" >
                    <div >
                        <Form.Check
                            type="radio"
                            name="addressType"
                            value={13}
                            label={"sai murali"}
                            onChange={(e) => setchecked(e.target.value)}
                            checked={true}
                            // defaultChecked={each.default_address ? true : false}
                            aria-label="radio 1"
                        />
                    </div>

                </div>
                <div className='checkout-list-address'>
                    KPHB,Dharma reddy colony,jntu
                    <div>Hyderabad, Telangana - <span>500032</span></div>
                    <div>8500332883</div>


                </div>
            </div> */}
        </>

    );
};

export default PickupAddressList;