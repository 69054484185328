import React, { useState, useContext, useEffect } from 'react';
import {
    Form,
} from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import AddressAction from "../../redux/actions/AddressAction";
import { useSelector,useDispatch } from 'react-redux';

const ModalAddressListComp = ({ type, showbtn, addressList }) => {

    const dispatch = useDispatch()

    const [checked, setchecked] = useState();

    const { updateAddressfun } = AddressAction();

    const openaddressModal = useSelector(state => state.addressdata.openaddressModal);

    useEffect(() => {
        setchecked(addressList[0].addressId)
    }, [addressList])


    const handleUpdateAddress = (addressId) => {
        setchecked(addressId)
        const input_data = {
            "default": "1",
            "addressId": addressId
        }
        dispatch(updateAddressfun(input_data))
        dispatch({ type: 'setopenaddressModal', payload: !openaddressModal })
    }

    return (
        <>
            {
                addressList.filter(elefilter => elefilter.addresstype == 'Delivery').length > 0 && addressList.filter(elefilter => elefilter.addresstype == 'Delivery').map(ele => {
                    return (
                        <div className='accordion-address-body-listitem' style={{ cursor: "pointer" }}>
                            <div className="d-flex justify-content-between" >
                                <div onClick={() => handleUpdateAddress(ele.addressId)} style={{ cursor: "pointer" }}>
                                    <Form.Check
                                        type="radio"
                                        name="addressType"
                                        value={ele.addressId}
                                        label={ele.name}
                                        onChange={(e) => setchecked(e.target.value)}
                                        checked={checked == ele.addressId}
                                        // defaultChecked={each.default_address ? true : false}
                                        aria-label="radio 1"
                                        style={{ cursor: "pointer" }}
                                    />
                                </div>

                            </div>
                            <div className='checkout-list-address'>
                                {ele.address}
                                <div>Hyderabad, {ele.state} - <span>{ele.pincode}</span></div>


                            </div>
                        </div>

                    )
                })
            }

        </>

    );
};

export default ModalAddressListComp;