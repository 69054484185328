import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";

import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from 'react-redux';
const CustomDatePicker = ({ state, masterData, setactivestate1 }) => {
    const dispatch = useDispatch()

    const minDate = moment(); // Set minimum date to today
    const [maxdate, setmaxdate] = useState(0);
    const [startDate, setStartDate] = useState(null);
    const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <div className="custom-time-slot" onClick={onClick} ref={ref}>
            {startDate == null ? "Select Date" : value}
        </div>
    ));


    var date = new Date();

    useEffect(() => {
        if (Object.keys(masterData).length > 0 && masterData.vendor.hasOwnProperty("delivery")) {
            setmaxdate(masterData.vendor.delivery.calender_days)
        }
    }, [masterData])

    useEffect(() => {
        if (state == 1 || state == 2) {
            setStartDate(null)
        }
    }, [state])

    return (
        <DatePicker
            selected={startDate}
            onChange={(date) => {
                setStartDate(date)
                dispatch({
                    type: 'setdeliverdatetime', payload:{
                        "date": moment(date).format('DD-MM-YYYY'),
                        "day": moment(date).format('dddd'),
    
                    }
                })
                if (Object.keys(masterData).length > 0 && masterData.vendor.hasOwnProperty("delivery")) {
                    var ty = masterData.vendor.delivery.slot.filter(ele => ele.day == moment(date).format('dddd'))
                    if (ty.length > 0) {
                        setactivestate1(ty[0].time[0].time);
                    }
                }
            }}
            customInput={<ExampleCustomInput />}
            minDate={date}
            // maxDate={new Date("12-01-2023")}
            maxDate={new Date(moment(date).add(maxdate - 1, 'days').format('MM-DD-YYYY'))}

        // maxDate={new Date(date.getMonth()-date.getDate()-date.getFullYear()+3)}
        // maxDate={date.setDate(date.getDate()+5)}
        />
    );
};

export default CustomDatePicker;